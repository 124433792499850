var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settingTableComponent"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addNewSize}},[_vm._v(" 添加尺码 ")]),_c('CommonTable',{attrs:{"height":"auto","infoData":_vm.domData,"cols":_vm.cols,"selection":false,"header-cell-style":{
      background: '#f7f7f7',
      padding: 0,
      color: '#000',
    }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addNewSize}},[_vm._v(" 添加尺码 ")])]},proxy:true},{key:"sizeSlot",fn:function(ref){
    var index = ref.scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"尺码","value":_vm.domData[index].size,"validFunc":_vm.validFunc},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], "size", $event)}}})]}},{key:"weightSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"重量","value":_vm.domData[index][prop]},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], prop, $event)}}})]}},{key:"volumeSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"体积","value":_vm.domData[index][prop]},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], prop, $event)}}})]}},{key:"lengthSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"长度","value":_vm.domData[index][prop]},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], prop, $event)}}})]}},{key:"widthSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"宽度","value":_vm.domData[index][prop]},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], prop, $event)}}})]}},{key:"heightSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"高度","value":_vm.domData[index][prop]},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], prop, $event)}}})]}},{key:"priceSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('CheckInput',{attrs:{"type":"text","size":"mini","label":"价格","value":_vm.domData[index][prop]},on:{"update:value":function($event){return _vm.$set(_vm.domData[index], prop, $event)}}})]}},{key:"standardSlot",fn:function(ref){
    var row = ref.scoped;
    var ref_scoped = ref.scoped;
    var prop = ref_scoped.prop;
    var index = ref_scoped.index;
return [_c('el-radio-group',{model:{value:(_vm.domData[index][prop]),callback:function ($$v) {_vm.$set(_vm.domData[index], prop, $$v)},expression:"domData[index][prop]"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("否")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("是")])],1)]}}])},[_vm._t("default",[_c('el-table-column',{attrs:{"label":"操作","width":"100px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(scoped.$index)}}},[_vm._v(" 删除 ")])]}}])})])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }